<template>
  <div class="support-login">
    <base-loading :waitFlg="waitFlg" />
    <validation-observer
      class="validation-observer"
      ref="observer"
      v-slot="{ invalid }"
    >
      <div class="support-time" v-if="supportTime.end !== null">
        <span>
          {{ supportTime.start.format('M月D日のH:mm') }}から、{{
            supportTime.end.format('M月D日のH:mm')
          }}までログイン権限を付与しました。</span
        ><base-button-small-red
          class="button"
          v-if="lookOnlyFlg === 0"
          :disabled="waitFlg"
          @click="stopSupport"
          >停止</base-button-small-red
        >
      </div>
      <div class="support-time-form">
        <div class="row">
          <div class="label">設定時間<hint-balloon :note="note" /></div>
          <div class="field">
            <v-date-picker
              :input-props="{ class: 'v-date-picker', readonly: true }"
              :min-date="dateObj.toDate()"
              v-model="supportStartDate"
              is-required
              data-test="v-date-picker"
            />
            <base-time-input-field
              v-model="supportStartTime"
              :minuteInterval="1"
              :input-width="'75px'"
              :format="'HH:mm'"
            />
            <div class="following-text">～</div>
            <base-time-input-field
              v-model="supportEndTime"
              :minuteInterval="1"
              :input-width="'75px'"
              :format="'HH:mm'"
            />
            <span v-show="nextDayFlg">（翌）</span>
          </div>
        </div>
        <div class="row">
          <div class="label">登録編集</div>
          <div class="field">
            <base-check-box
              :labelText="'可能'"
              :isChecked="supportEditData"
              v-model="supportEditData"
            />
          </div>
        </div>
      </div>
      <div class="privacy-policy">
        <div class="aaa">
          顧客の個人情報を含む医院の情報はサポートのためだけに使用します
        </div>
        <a href="https://www.pyxos-jk.co.jp/privacyPolicy.html" target="_blank"
          >プライバシーポリシーの確認はこちらから</a
        >
        <base-check-box
          class="check-box"
          :labelText="'プライバシーポリシーに同意する'"
          :isChecked="acceptPrivacyPolicy"
          v-model="acceptPrivacyPolicy"
        />
      </div>
      <div class="button-wrapper">
        <base-button-medium-orange
          class="button"
          v-if="lookOnlyFlg === 0"
          :disabled="
            invalid || !acceptPrivacyPolicy || waitFlg || supportFlg === 1
          "
          @click="updateSupport"
          >送信</base-button-medium-orange
        >
      </div>
    </validation-observer>
    <announce-popup
      v-if="alertFlg"
      @close="alertFlg = false"
      :title="title"
      :buttons="['閉じる']"
      :type="type"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import HintBalloon from '@/components/parts/atoms/HintBalloon'
import BaseTimeInputField from '@/components/parts/atoms/BaseTimeInputField'
import moment from 'moment'
import { mapGetters } from 'vuex'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed.vue'
import BaseLoading from '@/components/parts/atoms/BaseLoading'

export default {
  name: 'SupportLogin',
  components: {
    BaseLoading,
    ValidationObserver,
    BaseButtonMediumOrange,
    BaseCheckBox,
    AnnouncePopup,
    HintBalloon,
    BaseTimeInputField,
    BaseButtonSmallRed
  },
  data() {
    return {
      waitFlg: false,
      acceptPrivacyPolicy: false,
      note: 'サポート用にログインを許可する時間を設定して下さい。',
      alertFlg: false,
      title: '',
      type: '',
      popupMessage: '',
      dateObj: null,
      supportStartDate: '',
      supportStartTime: '',
      supportEndTime: '',
      supportEditData: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      supportFlg: 'auth/supportFlg',
      staffs: 'staffs/getData'
    }),
    nextDayFlg() {
      return this.supportEndTime
        ? moment(this.supportEndTime, 'HH:mm').diff(
            moment(this.supportStartTime, 'HH:mm')
          ) < 0
        : false
    },
    supportTime() {
      const supportStaff = this.staffs.find(
        s => s.supportLoginStartTime !== null
      )
      const startObj = moment(supportStaff.supportLoginStartTime)
      const endObj = moment(supportStaff.supportLoginEndTime)
      const now = moment()
      const end = now.diff(endObj) < 0 ? endObj : null
      return { start: startObj, end }
    }
  },

  created() {
    this.dateObj = moment()
    this.supportStartDate = this.dateObj.toDate()
    this.supportStartTime = this.dateObj.format('HH:mm')
    this.supportEndTime = this.dateObj.add(1, 'hours').format('HH:mm')
  },

  methods: {
    async updateSupport() {
      this.waitFlg = true
      const start = moment(this.supportStartDate)
        .set('hour', this.supportStartTime.slice(0, 2))
        .set('minute', this.supportStartTime.slice(3, 5))
      const end = moment(this.supportStartDate)
        .set('hour', this.supportEndTime.slice(0, 2))
        .set('minute', this.supportEndTime.slice(3, 5))
      if (end.diff(start) < 0) end.add(1, 'day')
      const result = await this.$store.dispatch('staffs/updateSupportStaff', {
        start: start.toDate(),
        end: end.toDate(),
        lookOnlyFlg: this.supportEditData ? 0 : 1
      })
      if (result === 'success') {
        this.alertFlg = true
        this.title = '完了'
        this.type = 'success'
        const startString = moment(start).format('M月D日のH:mm')
        const endString = moment(end).format('M月D日のH:mm')
        this.popupMessage = `${startString}から、${endString}まで\nログイン権限を付与しました。`
      } else {
        this.alertFlg = true
        this.title = '失敗'
        this.type = 'failure'
        if (result === 'permission denied') {
          this.popupMessage = '権限不足のため失敗しました。'
        } else if (result === 'no data') {
          this.popupMessage = '失敗しました。'
        } else {
          this.popupMessage =
            'ログイン権限の付与に失敗しました。電波の良い所で再度お試し下さい。'
        }
      }
      this.waitFlg = false
    },
    async stopSupport() {
      this.waitFlg = true
      const start = this.supportTime.start
      const end = moment()
      const result = await this.$store.dispatch('staffs/updateSupportStaff', {
        start: start.toDate(),
        end: end.toDate(),
        lookOnlyFlg: this.supportEditData ? 0 : 1
      })
      if (result === 'success') {
        this.alertFlg = true
        this.title = '完了'
        this.type = 'success'
        this.popupMessage = `ログイン権限を剥奪しました。`
      } else {
        this.alertFlg = true
        this.title = '失敗'
        this.type = 'failure'
        if (result === 'permission denied') {
          this.popupMessage = '権限不足のため失敗しました。'
        } else if (result === 'no data') {
          this.popupMessage = '失敗しました。'
        } else {
          this.popupMessage =
            'ログイン権限の剥奪に失敗しました。電波の良い所で再度お試し下さい。'
        }
      }
      this.waitFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.support-login {
  width: 650px;
  font-size: 15px;
  > .validation-observer {
    > .support-time {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;
    }
    > .support-time-form {
      > .row {
        display: flex;
        align-items: center;

        margin-top: 30px;
        > .label {
          display: flex;
          align-items: center;
          width: 220px;
          margin-right: 20px;
        }
        > .field {
          display: flex;
          width: 410px;
          align-items: center;
          ::v-deep {
            > span {
              .v-date-picker {
                text-align: right;
                padding-right: 12px;
                width: 140px;
                height: 33px;
                border-radius: 5px;
                border: solid 2px #{$light-grey};
                text-indent: 5px;
                font-size: 13px;
                cursor: pointer;
                background-image: url('../../../assets/images/calendar.png');
                background-size: 18px 18px;
                background-repeat: no-repeat;
                background-position: 5px center;
                margin-right: 20px;
              }
            }
          }
          > select-box-form {
            width: 75px;
          }
          > .following-text {
            margin: 0 10px;
          }
        }
      }
    }
    > .privacy-policy {
      margin-top: 40px;
      text-align: center;
      > .aaa {
        font-size: 15px;
      }
      > a {
        color: blue;
        text-decoration: underline;
        &:hover {
          opacity: 0.8;
          text-decoration: none;
        }
      }
      > .check-box {
        width: 225px;
        margin: 10px auto 0;
      }
    }
    > .button-wrapper {
      display: flex;
      justify-content: center;
      margin: 60px 0;
    }
  }
}
</style>
