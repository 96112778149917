<template>
  <div class="contact-us">
    <validation-observer
      class="validation-observer"
      ref="observer"
      v-slot="{ invalid }"
    >
      <div class="forms">
        <select-box-form
          class="form"
          :requiredFlg="true"
          :selectData="contactTypes"
          :styles="boxStyles"
          v-model="contact.contactTypeId"
          >お問い合わせ項目</select-box-form
        >
        <select-box-form
          class="form"
          :requiredFlg="true"
          :selectData="selectDataStaffs"
          :styles="boxStyles"
          v-model="contact.staffId"
          >担当者名</select-box-form
        >
        <image-form
          class="form"
          ref="imageForm"
          :image="contact.image"
          @input-image="inputImage"
          @delete-image="deleteImage"
          >添付画像</image-form
        >
        <multiple-text-input-form
          class="form"
          :requiredFlg="true"
          v-model="contact.message"
          >お問い合わせ内容</multiple-text-input-form
        >
      </div>
      <div class="button-wrapper">
        <base-button-medium-orange
          class="button"
          v-if="lookOnlyFlg === 0"
          :disabled="invalid || waitFlg"
          @click="inquire"
          >送信</base-button-medium-orange
        >
      </div>
      <announce-popup
        v-if="alertFlg"
        @close="closeAlert"
        :title="title"
        :buttons="buttons"
        :type="type"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import MultipleTextInputForm from '@/components/parts/molecules/MultipleTextInputForm'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { mapGetters } from 'vuex'
import { getAxiosObject } from '@/utils/library'
import { ValidationObserver } from 'vee-validate'
import ImageForm from '@/components/parts/molecules/ImageForm'

export default {
  name: 'ContactUs',
  components: {
    SelectBoxForm,
    MultipleTextInputForm,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver,
    ImageForm
  },
  mixins: [CheckInputDifference],
  data() {
    return {
      waitFlg: false,
      contact: {},
      boxStyles: { height: '33px', width: '202px' },
      alertFlg: false,
      title: '',
      buttons: ['閉じる'],
      type: '',
      popupMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      contactTypes: 'master/getContactTypes',
      selectDataStaffs: 'staffs/selectData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },
  created() {
    this.setData()
  },
  methods: {
    setData() {
      this.contact = {
        contactTypeId: 1,
        staffId: this.$store.getters['auth/loginStaffId'],
        message: '',
        image: null
      }
      this.mixinInputData = this.contact
      this.mixinSetInitialData()
    },
    inputImage(image) {
      this.contact.image = image
    },
    deleteImage() {
      this.contact.image = null
      this.resetInputTag()
    },
    async inquire() {
      this.waitFlg = true
      try {
        const axiosObject = getAxiosObject()
        const res = await axiosObject.post('/contacts', {
          contact: this.contact
        })
        if (res.status === 200) {
          this.title = '完了'
          this.type = 'success'
          this.popupMessage = 'お問い合わせ内容を送信しました'
          this.alertFlg = true
          this.setData()
          this.$refs.observer.reset()
          this.resetInputTag()
        }
      } catch (error) {
        if (
          error.response?.data?.message === 'no data' &&
          error.response.data.extra === 'staff'
        ) {
          this.contact.staffId = this.$store.getters['auth/loginStaffId']
          this.title = '失敗'
          this.type = 'failure'
          this.popupMessage = `既に削除されたスタッフを担当者に指定しています\n最新データに更新しますので再編集をお願いします`
          this.alertFlg = true
        } else {
          this.title = '失敗'
          this.type = 'failure'
          this.popupMessage = 'お問い合わせ内容送信に失敗しました'
          this.alertFlg = true
        }
      }
      this.waitFlg = false
    },
    resetInputTag() {
      this.$refs.imageForm.$refs.inputTag.type = 'text'
      this.$refs.imageForm.$refs.inputTag.type = 'file'
    },
    closeAlert() {
      this.alertFlg = false
      this.title = ''
      this.type = ''
      this.popupMessage = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-us {
  width: 100%;
  height: 100%;
  text-align: left;
  > .validation-observer {
    > .title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .forms {
      margin-top: 29px;
      > .form {
        width: 600px;
        margin-bottom: 20px;
      }
    }
    > .button-wrapper {
      display: flex;
      justify-content: center;
      margin: 60px 0;
    }
  }
}
</style>
